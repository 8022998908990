export function setData(e, type, id) {
  e.dataTransfer.setData(type, "" + id);
}

export function getData(e) {
  let type = e.dataTransfer.types[0];
  return { type, id: +e.dataTransfer.getData(type) };
}

export function treeElemPos(parent, loc) {
  let children = parent.children;
  let array = loc.type === "unit" ? children : parent.teams;
  if (array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === loc.id) {
        return { parent, i, array };
      }
    }
  }
  if (children) {
    for (let i = 0; i < children.length; i++) {
      let c = treeElemPos(children[i], loc);
      if (c) {
        return c;
      }
    }
  }
}

export function getTreeElem(found) {
  return found.array[found.i];
}

export function subArray(parent, name) {
  if (!parent[name]) {
    parent[name] = [];
  }
  return parent[name];
}

export function sortChildren(parent) {
  if (parent.children) {
    parent.children.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    for (let i = 0; i < parent.children.length; i++) {
      sortChildren(parent.children[i]);
    }
  }
}

export function addTreeElemSorted(parent, elem) {
  parent.push(elem);
  parent.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
}
