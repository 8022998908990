import { getWeekStartByLocale } from "weekstart";
import { userLocale } from "@/utils";

const dateFormatter =
  window.Intl && window.Intl.DateTimeFormat
    ? new Intl.DateTimeFormat(userLocale(), {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : {
        format: (val) =>
          `${val.getFullYear()}/${pad(val.getMonth() + 1)}/${pad(
            val.getDate()
          )}`,
      };

const numberFormatter =
  window.Intl && window.Intl.NumberFormat
    ? new Intl.NumberFormat(userLocale())
    : { format: (val) => val };

function pad(s) {
  return s < 10 ? "0" + s : s;
}

export function formatCurrency(value) {
  return "$" + numberFormatter.format(Math.round(value || 0));
}

export function formatDate(value) {
  return dateFormatter.format(value);
}

/**
 * Format the given Date as a string 'YYYY-MM-DD' for input fields
 */
export function formatDateForInput(value) {
  return value?.toISOString?.().slice(0, 10);
}

export function weekStartsOnMonday() {
  return getWeekStartByLocale(userLocale()) === 1;
}

export function capitalize(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}
