import { authAPIUrl } from "@/environment";
import { LicenseService } from "@/services/license.service";
import { RemoteCaller } from "./remoteCaller";

export class LicenseHandler {
  constructor(company, getWsSession, apiVersion = 3, isUsingRest = false) {
    this.remote = new RemoteCaller(
      "auth",
      apiVersion,
      company,
      getWsSession,
      "licenses"
    );

    this.licenseService = new LicenseService(authAPIUrl);
    this.isUsingRest = isUsingRest;
  }

  removeTeamLicense(licenseId) {
    return this.remote.call("remove_team_license", [licenseId]);
  }

  addTeamLicense(teamId) {
    return this.remote.call("add_team_license", [teamId]);
  }

  async getCompanyLicense() {
    if (this.isUsingRest) {
      const res = await this.licenseService.getLicense();
      const data = res.data;
      return {
        data: {
          isExpired: data.is_expired,
          isValid: data.is_valid,
          usedTeamLicenses: data.used_team_licenses,
          numberOfTeams: data.number_of_teams,
          expiryDate: Date.parse(data.expiry_date) / 1000,
          sso: data.sso,
          tracking: data.tracking,
          onprem: data.onprem,
          plan: data.plan,
          type: data.type,
        },
        success: res.success,
      };
    }
    return this.remote.call("get_company_license");
  }
}
