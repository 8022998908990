import { RemoteCaller } from "./remoteCaller";

export class SessionsHandler {
  constructor(company, getWsSession, apiVersion = 6) {
    this.remote = new RemoteCaller(
      "piplanning",
      apiVersion,
      company,
      getWsSession
    );
  }

  getSession(id) {
    return this.remote.call("get_session", [id]);
  }

  getSessions() {
    return this.remote.call("get_sessions", []);
  }

  createTemplateSession(type) {
    return this.remote.call("create_template_session", [type]);
  }

  getTemplateSessions() {
    return this.remote.call("get_available_template_sessions", []);
  }

  onSession(callback) {
    return this.remote.subscribe("on_sessions", callback);
  }

  getUsersSeeAllSessions() {
    return this.remote.call("get_users_see_all_sessions");
  }

  setUsersSeeAllSessions(value) {
    return this.remote.call("set_users_see_all_sessions", [value]);
  }
}
