import { createApiClient } from "@/services/apiClient";

const baseURL = "v1/identity-providers";

export class IdentityProviderService {
  constructor(authAPIUrl, companyID) {
    this.apiClient = createApiClient(authAPIUrl);
    this.companyID = companyID;
  }

  async getLicense() {
    const rsp = await this.apiClient.get(
      `v1/companies/${this.companyID}/license`
    );
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async updateOIDC(id, params) {
    const rsp = await this.apiClient.patch(`${baseURL}/${id}`, params);
    return {
      success: rsp.data.status === "success",
    };
  }

  async deleteProvider(id) {
    const rsp = await this.apiClient.delete(`${baseURL}/${id}`);
    return {
      success: rsp.data.status === "success",
    };
  }

  async getProviders() {
    const rsp = await this.apiClient.get(baseURL);
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async addOIDC(name, config) {
    const rsp = await this.apiClient.post(`${baseURL}/oidc`, {
      name: name,
      config: config,
    });

    return {
      success: rsp?.data.status === "success",
      data: rsp?.data.data.id,
    };
  }

  async addSAML() {
    const rsp = await this.apiClient.post(`${baseURL}/saml`);
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data.id,
    };
  }
}
