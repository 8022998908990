import { RemoteCaller } from "./remoteCaller";

export class AlmEventHandler {
  constructor(company, getWsSession, apiVersion = 6) {
    this.remote = (almTool, connectionId) => {
      const submodule = `almaudit.${almTool}.${connectionId}.events`;
      return new RemoteCaller(
        "piplanning",
        apiVersion,
        company,
        getWsSession,
        submodule
      );
    };
  }

  count(almTool, connectionId) {
    return this.remote(almTool, connectionId).call("count");
  }

  list(almTool, connectionId, limit) {
    return this.remote(almTool, connectionId).call("list", [limit]);
  }
}
