<template>
  <nav :class="$style['sidebar']">
    <div :class="$style['menu']">
      <ul :class="$style['menu-items-group']">
        <sidebar-link :to="{ name: 'safepiplannings', query: query() }">
          <base-icon icon="planning" />
          SAFe PIs
        </sidebar-link>

        <sidebar-link :to="{ name: 'organization', query: query() }">
          <base-icon icon="organization" />
          Organization
        </sidebar-link>

        <sidebar-link :to="{ name: 'users', query: query() }">
          <base-icon is-stroked icon="users" />
          Users
        </sidebar-link>

        <sidebar-link v-if="hasUnits" :to="{ name: 'units', query: query() }">
          <base-icon is-stroked icon="units" />
          Units
        </sidebar-link>
      </ul>
      <ul :class="$style['menu-items-group']">
        <sidebar-link :to="{ name: 'almConnections', query: query() }">
          <base-icon is-stroked icon="integration" />
          ALM Connections
        </sidebar-link>

        <sidebar-link
          v-if="isAdmin && !isTraining"
          :to="{ name: 'SelectIdentityProvider', query: query() }"
          not-allowed-title="SSO is part of our premium plan"
        >
          <base-icon icon="shield" />
          SSO
        </sidebar-link>

        <sidebar-link
          :class="{ highlight: expiring }"
          :to="{
            name: isTraining ? 'trainingBilling' : 'billing',
            query: query(),
          }"
        >
          <base-icon is-stroked icon="billing" />
          Billing
        </sidebar-link>

        <sidebar-link :to="{ name: 'settings', query: query() }">
          <base-icon is-stroked icon="settings" />
          Settings
        </sidebar-link>
      </ul>

      <ul :class="$style['menu-items-group']">
        <sidebar-link :to="{ name: 'help', query: query() }">
          <base-icon icon="help" />
          Help
        </sidebar-link>
        <li :class="$style['external-link']">
          <a
            href="https://www.piplanning.io/legal/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
        </li>
        <li :class="$style['external-link']">
          <a
            href="https://www.piplanning.io/legal/terms-of-service"
            target="_blank"
          >
            Terms of Service
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { getFeatureQuery, isFeatureEnabled } from "@/feature";
import { isCloud } from "@/environment";
import { mapGetters, mapState } from "vuex";
import SidebarLink from "@/components/SidebarLink.vue";
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  name: "TheSidebar",
  components: { SidebarLink, BaseIcon },
  data() {
    return {
      isCloud,
    };
  },
  computed: {
    expiring() {
      return this.expireDays != null && this.expireDays < 30;
    },
    ...mapState(["hasUnits", "expireDays"]),
    ...mapGetters(["isAdmin", "isTraining"]),
  },
  methods: {
    isFeatureActive(featureName) {
      return isFeatureEnabled(this.$route, featureName);
    },
    query() {
      return getFeatureQuery(this.$route);
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.sidebar {
  background-color: $lightPurpleColor;
  width: $sidebar-width;
  height: calc(100% - 4rem);
  position: fixed;
  top: $header-height;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 2;

  .menu {
    padding: 2rem 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 2.375rem;
    height: inherit;

    .menu-items-group {
      margin: 0;

      &:nth-last-of-type(1) {
        margin-top: auto;
      }

      .external-link {
        display: inline-flex;
        padding: 0;
        margin: 0;
        width: 100%;
        border-radius: 8px;

        a {
          display: inline-flex;
          align-items: center;
          font-size: $font-small;
          color: $darkGreyColor;
          width: calc(100% - 2rem);
          padding: 0.375rem 1rem;
        }

        &:hover:not([disabled]) {
          background-color: rgba($purpleColor, 0.06);
        }
      }

      :last-of-type {
        margin-top: auto;
      }
    }
  }
}
</style>
