<template>
  <div v-if="!display || display(unit)">
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div
      v-show="!root || showRoot"
      class="parent"
      :draggable="canEdit"
      :class="{ marked: mark === 'item' }"
      @dragstart="dragStart"
      @dragenter.prevent="setMark($event, 'item')"
      @dragover.stop.prevent
      @dragleave="unmark($event)"
      @drop="drop('into', $event)"
    >
      <button
        class="tree-expand"
        :class="{ open: open, hide: !hasChildren(unit) }"
        :aria-label="open ? 'Collapse' : 'Expand'"
        @click="toggle()"
      />
      <span class="drag-icon" :class="{ hide: !canEdit }" />
      <span class="name">{{ unit.name }}</span>
      <action-button
        v-if="canEdit"
        action="edit"
        :title="`Edit ${unit.name}`"
        class="col-2 action"
        @click="action('editUnit')"
      />
      <action-button
        v-if="canEdit"
        action="delete"
        :title="`Delete ${unit.name}`"
        class="col-1 action"
        @click="action('deleteUnit')"
      />
    </div>
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div
      class="between"
      :class="{ marked: mark === 'between', root }"
      @dragenter.prevent="setMark($event, 'between')"
      @dragover.stop.prevent
      @dragleave="unmark($event)"
      @drop="drop('below', $event)"
    />
    <unit
      v-for="child in unit.children"
      :key="child.id"
      :unit="child"
      :root="false"
      :editable="editable"
      :display="display"
      class="children"
      :class="{ close: !open }"
      @action="bubble"
    />
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton.vue";
import { getData, setData } from "./treeFunctions";

export default {
  name: "Unit",
  components: { ActionButton },
  props: {
    unit: {
      type: Object,
      required: true,
    },
    root: Boolean,
    showRoot: Boolean,
    editable: Boolean,
    display: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      open: true,
      mark: null,
      markEl: null,
    };
  },
  computed: {
    canEdit() {
      return !this.root && this.editable;
    },
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    hasChildren(unit) {
      return (
        (unit.children && unit.children.length > 0) ||
        (unit.teams && unit.teams.length > 0)
      );
    },
    setMark(e, m) {
      if (m === "item") {
        this.mark = m;
        this.markEl = e.target;
      }
    },
    unmark(e) {
      if (e.target === this.markEl) {
        this.mark = null;
      }
    },
    action(a) {
      this.$emit("action", { type: a, value: this.unit });
    },
    bubble(e) {
      this.$emit("action", e);
    },
    dragStart(e) {
      setData(e, "unit", this.unit.id);
    },
    drop(action, e) {
      if (this.mark) {
        this.mark = null;
        this.$emit("action", {
          type: "drop",
          value: {
            action,
            from: getData(e),
            to: { type: "unit", id: this.unit.id },
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.parent {
  height: 40px;
  line-height: 40px;
  position: relative;
  margin-top: 10px;
  border: 1px solid transparent;

  &[draggable="true"] {
    cursor: grab;
  }
}

.between {
  height: 10px;
  border-bottom: 2px solid $darkBlueColor;

  &.root {
    border-bottom-color: transparent;
  }
}

.marked {
  border: 1px solid black;
}

.tree-expand {
  position: absolute;
  top: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid $darkBlueColor;
  transform: rotate(90deg);
  background: none;
  outline: revert;

  &.open {
    transform: rotate(180deg);
  }
}

.children {
  &.close {
    height: 0;
    overflow: hidden;
  }
}

.drag-icon {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 20px;
  height: 20px;
  background: url(~@/assets/drag.png) center/contain no-repeat;

  &.hide {
    width: 0;
    margin-right: 0;
  }
}

.name {
  margin-left: 60px;
  max-width: 60%;
  height: 2em;
  display: inline-block;
  overflow: hidden;
}

.action {
  margin: 5px 20px;
}
</style>
