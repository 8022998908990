import { almHandlerJira } from "@/handlers/almHandlerJira";
import { almHandlerAdo } from "@/handlers/almHandlerAdo";
import { almHandlerAc } from "@/handlers/almHandlerAc";
import { almHandlerCommon } from "@/handlers/almHandlerCommon";
import { RemoteCaller } from "@/handlers/remoteCaller";

const AlmHandler = {
  install(Vue) {
    Vue.prototype.$almHandler = function (sessionInfo) {
      return this.$runFnToCall((session) => {
        const type = sessionInfo.alm_connection_type || sessionInfo.almTool;
        const connectionID =
          sessionInfo.alm_connection_id || sessionInfo.selectedConnectionID;
        const almSessionId = sessionInfo.sync_session_id;
        const sessionBase =
          this.$getALMBase() + ".session." + almSessionId + ".";

        const remote = new RemoteCaller(
          "piplanning",
          this.$PIAPIVersion,
          this.$store.getters.company,
          () => session,
          "alm." + type
        );
        return Object.assign(
          almHandlerCommon(remote, connectionID, almSessionId),
          {
            onStatus: (handler) => {
              return session.subscribe(sessionBase + "on_status", handler);
            },
          },
          almHandler(type)
        );

        function almHandler(type) {
          switch (type) {
            case "jira":
              return almHandlerJira(remote, connectionID, almSessionId);
            case "ado":
              return almHandlerAdo(remote, connectionID, almSessionId);
            case "ac":
              return almHandlerAc(remote, connectionID, almSessionId);
          }
        }
      });
    };
  },
};

export default AlmHandler;

export function setTakenSprintIDs(iterations, currentTeamID, currentSourceID) {
  let takenSprintIDs = [];
  iterations.forEach((iteration) => {
    iteration.teams.forEach((team) => {
      if (team.id === currentTeamID) {
        team.sources.forEach((source) => {
          if (source.id === currentSourceID) {
            if (source.sprint > 0) {
              takenSprintIDs.push(source.sprint);
            }
          }
        });
        team.takenSprintIDs[currentSourceID] = takenSprintIDs;
      }
    });
  });
}
