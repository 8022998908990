<template>
  <div
    :class="['main-section', $style['pi-plannings']]"
    aria-labelledby="pi-plannings-header"
  >
    <h2 id="pi-plannings-header">PIs</h2>
    <div :class="$style['test']">
      <input
        id="archived"
        v-model="archived"
        type="checkbox"
        class="custom-checkbox pointer"
      />
      <label for="archived" class="fs-semi-medium pointer">
        Show archived sessions
      </label>
    </div>
    <div :class="$style['content-wrap']">
      <div :class="$style['table-wrap']">
        <data-table>
          <template #default="{ align }">
            <tr>
              <th scope="col">Name</th>
              <th v-if="hasUnit" scope="col" :class="$style['units-column']">
                Unit
              </th>
              <th scope="col" :class="[align.center, $style['status-column']]">
                Status
              </th>
              <td class="th" :class="[align.center, $style['action-column']]">
                Edit
              </td>
              <td class="th" :class="[align.center, $style['action-column']]">
                Action
              </td>
            </tr>
            <tr
              v-for="session in filteredSessions"
              :key="session.session_id"
              :class="{ [$style.archived]: session.archived }"
            >
              <th scope="row">{{ session.name }}</th>
              <td v-if="hasUnit" :class="$style['units-column']">
                {{ session.unitName }}
              </td>
              <td :class="[align.center, $style['status-column']]">
                <div v-if="!session.archived">
                  <div
                    v-if="session.almStatus === 'error'"
                    :class="$style['sync-action']"
                  >
                    <div :class="$style['icon-wrapper']">
                      <base-icon
                        icon="error"
                        role="img"
                        aria-label="ALM Sync Error"
                        :class="[$style['error-color']]"
                      />
                    </div>
                    <button
                      :class="$style['fix']"
                      @click="editAlmConnection(session)"
                    >
                      Fix
                    </button>
                  </div>
                  <div
                    v-if="session.almStatus === 'running'"
                    :class="$style['sync-action']"
                  >
                    <div :class="$style['icon-wrapper']">
                      <base-icon
                        icon="success"
                        role="img"
                        aria-label="ALM Sync Running"
                        :class="[$style['success-color']]"
                      />
                    </div>
                    <button
                      :class="$style['stop']"
                      @click="stopALMSync(session)"
                    >
                      Stop Sync
                    </button>
                  </div>
                  <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
                  <div
                    v-else-if="session.almStatus === 'stopped'"
                    :class="$style['sync-action']"
                    @mousemove="showHiddenForceRun = $event.altKey"
                    @mouseleave="showHiddenForceRun = false"
                  >
                    <div :class="$style['icon-wrapper']">
                      <base-icon
                        icon="stopped"
                        title="Force Run"
                        role="img"
                        aria-label="ALM Sync Stopped"
                        :class="[$style['stopped-color']]"
                      />
                    </div>
                    <button
                      v-if="showHiddenForceRun"
                      :class="$style['restart']"
                      @click="forceALMRunning(session)"
                    >
                      Force Run
                    </button>
                    <button
                      v-else
                      :class="$style['restart']"
                      @click="startALMSync(session)"
                    >
                      Restart
                    </button>
                  </div>
                  <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
                  <div
                    v-else-if="session.almStatus === 'starting'"
                    :class="$style['sync-action']"
                    @mousemove="showHiddenStop = $event.altKey"
                    @mouseleave="showHiddenStop = false"
                  >
                    <div :class="$style['sync-state']">
                      <base-icon icon="loading" :class="$style['loading']" />
                    </div>
                    <button
                      v-if="showHiddenStop"
                      :class="$style['stop']"
                      @click="stopALMSync(session)"
                    >
                      Force Stop
                    </button>
                  </div>
                </div>
              </td>
              <td :class="[align.center, $style['action-column']]">
                <action-button
                  action="edit"
                  title="Edit"
                  :disabled="!canEditSession(session)"
                  @click="edit(session.session_id)"
                />
              </td>
              <td :class="[align.center, $style['action-column']]">
                <action-menu
                  title="Action menu"
                  :data-testid="`action-menu-${session.name}`"
                >
                  <!-- Keyboard-nav (in action-menu) ensures keyboard interactions work -->
                  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
                  <li
                    v-if="canEditSession(session)"
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click="edit(session.session_id, 'action-menu')"
                  >
                    Edit
                  </li>
                  <li
                    v-if="canEditSession(session) && !isTraining"
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click="showDuplicateModal(session.session_id)"
                  >
                    Duplicate
                  </li>
                  <li
                    v-if="canEditSession(session)"
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click="showImportModal(session.session_id, 'backlog')"
                  >
                    CSV Import (Backlog Board)
                  </li>
                  <li
                    v-if="canEditSession(session)"
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click="showImportModal(session.session_id, 'team')"
                  >
                    CSV Import (Team Boards)
                  </li>
                  <li
                    v-if="canEditSession(session)"
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click="showImportModal(session.session_id, 'jalign')"
                  >
                    Jira Align xlsx import
                  </li>
                  <li
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click="downloadXLSX(session)"
                  >
                    XLSX Download
                    <base-icon
                      v-show="xlsxDownloading"
                      icon="loading"
                      :class="$style['loading']"
                    />
                  </li>
                  <li
                    v-if="canEditSession(session)"
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click="showDeleteModal(session)"
                  >
                    Delete
                  </li>
                  <li
                    v-if="canEditSession(session) && !session.archived"
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click="showArchiveModal(session)"
                  >
                    Archive
                  </li>
                  <li
                    v-if="canEditSession(session) && session.archived"
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click.prevent="unarchiveSession(session.session_id)"
                  >
                    Unarchive
                  </li>
                  <!-- eslint-enable vuejs-accessibility/click-events-have-key-events -->
                </action-menu>
              </td>
            </tr>
          </template>
        </data-table>

        <base-button v-if="canCreateSession" @click="create()">
          {{ isTraining ? "Create new simulation" : "Prepare new PI" }}
        </base-button>
      </div>

      <aside v-if="!isTraining" :class="$style['coaching-wrap']">
        <div>
          <h3 class="fs-medium">Learn about new features!</h3>
          <p style="margin-top: 20px; line-height: 1.2em">
            Every week new functionalities are developed and released to further
            refine your experience with piplanning.io and remote planning.
          </p>
          <p style="margin-top: 20px; line-height: 1.2em">
            Click the link below to learn what's new in piplanning.io
          </p>
        </div>
        <button-anchor
          class="small"
          href="https://www.piplanning.io/releases"
          target="_blank"
        >
          Release Notes
        </button-anchor>
      </aside>
    </div>

    <delete-modal
      ref="delete"
      title="Delete this session"
      @delete="deleteSession"
    >
      Are you sure you want to delete '{{ toDelete.name }}'?
    </delete-modal>

    <import-modal ref="import" />
    <simulation-modal ref="simulation" />
    <archive-session-modal ref="archive" @changed="loadSessions" />
    <duplicate-modal ref="duplicate" />
  </div>
</template>

<script>
import SimulationModal from "@/components/session/simulationModal";
import DeleteModal from "@/components/DeleteModalLegacy.vue";
import DuplicateModal from "@/components/session/duplicateModal";
import ImportModal from "@/components/session/importModal.vue";
import { SessionsHandler, UnitHandler } from "@/handlers/handlerFactory";
import BaseButton from "@/components/BaseButton.vue";
import ButtonAnchor from "@/components/ButtonAnchor.vue";
import { getFeatureQuery, isFeatureEnabled } from "@/feature";
import { mapActions, mapGetters } from "vuex";
import { captureExceptionWithContext } from "@/sentry";
import DataTable from "@/components/DataTable.vue";
import ActionButton from "@/components/ActionButton.vue";
import ActionMenu from "@/components/ActionMenu.vue";
import BaseIcon from "@/components/icons/BaseIcon.vue";
import archiveSessionModal from "@/pages/authRequired/sessions/archiveSessionModal";
import SessionHandler from "@/handlers/sessionHandler";
import { AlmConfigurationHandler } from "@/handlers/almConfigurationHandler";
import { trackEvent } from "@/analytics/track";
import {
  safepiplanningsEditSessionButtonClicked,
  safepiplanningsNewButtonClicked,
  safepiplanningsSessionArchiveClicked,
  safepiplanningsSessionDeleteClicked,
  safepiplanningsXlsxDownloadClicked,
} from "@/analytics/events";
import * as localRepository from "@/components/session/sessionLocalStorageRepository";

export default {
  name: "SessionsPage",
  components: {
    ImportModal,
    BaseIcon,
    ActionButton,
    DataTable,
    DeleteModal,
    SimulationModal,
    DuplicateModal,
    BaseButton,
    ButtonAnchor,
    archiveSessionModal,
    ActionMenu,
  },
  data() {
    return {
      sessions: [],
      hasUnit: false,
      simulationInProgress: false,
      showHiddenStop: "",
      showHiddenForceRun: false,
      xlsxDownloading: false,
      toDelete: {},
      archived: false,
    };
  },

  computed: {
    ...mapGetters([
      "isAdmin",
      "isTraining",
      "company",
      "session",
      "isPlanningIntervalAdmin",
      "user",
      "canEditSessionWithArt",
      "canEditSessionWithTrain",
    ]),
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    almConfigurationHandler() {
      return new AlmConfigurationHandler(this.company, this.session);
    },
    hasEditSessionRole() {
      return this.isAdmin || this.isPlanningIntervalAdmin;
    },
    canCreateSession() {
      const sessionPerm = this.user.permissions.session;
      return (
        this.hasEditSessionRole &&
        (sessionPerm.train.length > 0 || sessionPerm.art.length > 0)
      );
    },
    sessionsHandler() {
      return new SessionsHandler(this.company, this.session);
    },
    unitHandler() {
      return new UnitHandler(
        this.company,
        this.session,
        3,
        this.isRestApiEnabled
      );
    },
    filteredSessions() {
      return this.archived
        ? this.sessions
        : this.sessions.filter((session) => !session.archived);
    },
  },
  async mounted() {
    await this.loadSessions();
    this.subscribeToSessions();
  },

  methods: {
    ...mapActions("toast", ["showMessage"]),
    sessionHandler(sessionId) {
      return new SessionHandler(sessionId, this.company, this.session);
    },
    canEditSession(session) {
      return (
        this.hasEditSessionRole &&
        (session.arts || []).every(this.canEditSessionWithArt)
      );
    },
    async loadSessions() {
      this.sessions = await this.sessionsHandler.getSessions();
      this.sessions.sort(
        (a, b) => (b.creation_date || 0) - (a.creation_date || 0)
      );
      this.sessions.forEach((session) =>
        this.getSyncStatus(session.session_id)
      );
      this.hasUnit =
        this.hasEditSessionRole &&
        this.sessions.some((session) => !!session.unit);
      if (this.hasUnit) {
        let { data: units } = await this.unitHandler.getUnitsByUser();
        if (!Array.isArray(units)) {
          captureExceptionWithContext(new Error("units are not an array"), {
            units,
          });
          units = [];
        }
        this.sessions.forEach((session) => {
          let unit = units.find((u) => u.id === session.unit);
          this.$set(session, "unitName", (unit && unit.name) || "");
        });
      }
    },
    async getSyncStatus(sessionID) {
      const sessionInfo =
        await this.almConfigurationHandler.getAlmConfiguration(sessionID);
      if (sessionInfo.alm_connection_id) {
        const session = this.sessions.find(
          (session) => session.session_id === sessionID
        );
        this.$set(session, "connectionID", sessionInfo.alm_connection_id);
        this.$set(session, "almTool", sessionInfo.alm_connection_type);
        this.$set(session, "sync_session_id", sessionInfo.sync_session_id);
        const almHandler = this.$almHandler(sessionInfo);
        const almStatus = await almHandler.getStatus();
        if (almStatus) {
          this.$set(session, "almStatus", almStatus);
          try {
            await almHandler.onStatus(([status]) =>
              this.onALMStatus(session, status)
            );
          } catch (err) {
            console.log("Error while subscribing", err);
          }
        }
      }
    },
    edit(id, trigger) {
      trackEvent(safepiplanningsEditSessionButtonClicked(trigger));

      this.$router.push({
        path: "edit-session",
        query: {
          path: "generalInformation",
          id: id,
          ...getFeatureQuery(this.$route),
        },
      });
    },
    editAlmConnection(session) {
      this.$router.push({
        name: "editAlmConnection",
        params: {
          connectionId: session.connectionID,
          almType: session.almTool,
        },
      });
    },
    subscribeToSessions() {
      this.sessionsHandler.onSession(this.loadSessions);
    },
    onALMStatus(session, almStatus) {
      console.log("status change for", session.id, almStatus);
      session.almStatus = almStatus;
    },
    async downloadXLSX(session) {
      this.xlsxDownloading = true;

      trackEvent(safepiplanningsXlsxDownloadClicked());

      try {
        const data = await this.sessionHandler(session.session_id).getXlsx();
        this.saveData(
          stringToArrayBuffer(atob(data)),
          `session_${session.name.replace(/[^A-Za-z0-9]+/g, "_")}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
        );
      } catch (error) {
        console.log("problem downloading XLSX", error);
        this.showMessage({
          message: `Could not download XLSX: ${error.message}`,
          type: "error",
        });
      } finally {
        this.xlsxDownloading = false;
      }

      function stringToArrayBuffer(s) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
    },
    async unarchiveSession(sessionID) {
      try {
        await this.sessionHandler(sessionID).unarchiveSession(sessionID);
        this.showMessage({
          message: `Unarchived successfully.`,
          type: "success",
        });
        await this.loadSessions();
      } catch (error) {
        this.showMessage({
          message: `Could not archive session: ${error.message}`,
          type: "error",
        });
      }
    },
    saveData(data, fileName, type = "text/csv") {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      let url = window.URL.createObjectURL(new Blob([data], { type: type }));
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    showDeleteModal(session) {
      trackEvent(safepiplanningsSessionDeleteClicked());
      this.toDelete = this.$refs.delete.show(session);
    },
    async deleteSession(sessionToDelete) {
      await this.sessionHandler(sessionToDelete.session_id).delete();
      this.$refs.delete.close();
    },
    create() {
      trackEvent(safepiplanningsNewButtonClicked(this.isTraining));

      if (this.isTraining) {
        this.$refs.simulation.show();
      } else {
        this.addPiPlanning();
      }
    },
    addPiPlanning() {
      localStorage.clear();
      this.$store.commit("clearStateManager");
      localRepository.setSettings({ iteration_load_critical: 100 });
      this.$router.push({
        name: "addnewsafepiplanning",
        query: getFeatureQuery(this.$route),
      });
    },
    stopALMSync(pipSession) {
      this.$almHandler(pipSession).stopSession();
    },
    startALMSync(pipSession) {
      this.$almHandler(pipSession).startSession();
    },
    forceALMRunning(pipSession) {
      this.$almHandler(pipSession).forceRunningSession();
    },
    showImportModal(session, type) {
      this.$refs.import.show(session, type);
    },
    showDuplicateModal(session) {
      this.$refs.duplicate.show(session);
    },
    showArchiveModal(session) {
      trackEvent(safepiplanningsSessionArchiveClicked());
      this.$refs.archive.show(session);
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";
@import "@/_colors.scss";
@import "@/styles/global.scss";
@import "@/styles/loading-icon.scss";

.pi-plannings {
  @include global-styles;

  .test {
    margin-left: 430px;
    margin-bottom: 30px;
  }

  .archived {
    color: $greyColor;
  }

  .content-wrap {
    display: flex;

    .table-wrap {
      flex: 0 0 75%;
      display: flex;
      flex-direction: column;

      table {
        margin-bottom: 32px;

        .action-column {
          overflow: initial;
          width: 128px;
        }

        .status-column {
          min-width: 172px;
        }

        .action-button {
          display: inline-block;
        }

        tr td,
        tr th {
          text-overflow: ellipsis;
          overflow: hidden;

          &:first-child {
            max-width: 256px;
          }

          &.units-column {
            max-width: 128px;
          }
        }
      }
    }

    .info-wrap {
      .info {
        border: 2px solid $darkBlueColor;
        padding: 16px;
      }

      h1 {
        margin-top: 0;
        font-size: $font-medium;
      }

      .release-button {
        width: 100%;
      }
    }

    .sync-action {
      display: flex;
      gap: 2px;
      justify-content: center;
      align-items: center;
      font-size: $font-normal;
      color: $white;
      width: max-content;
      margin: 0 auto;

      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border: 1px solid $grey;
        border-radius: 4px;
        position: relative;
      }

      button {
        cursor: pointer;
        height: 32px;
        padding: 5px 10px;
        color: $white;
        border-radius: 6px;
        outline: revert;

        &.stop {
          background-color: $btn-danger-bg;
        }

        &.fix {
          background-color: $btn-primary-bg;
        }

        &.restart {
          background-color: $btn-primary-bg;
        }
      }

      // Hide the button visually until user is interacting with that cell,
      // but keep it in the Accessibility Tree
      // :hover for mouse users, :focus-within for keyboard users
      &:not(:is(:hover, :focus-within)) button {
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
      }

      .sync-state {
        border: 1px solid $grey;
        width: 32px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        .loading {
          color: $icon-primary-color;
        }
      }
    }

    .coaching-wrap {
      margin-left: 32px;
      margin-top: 50px;

      & > div {
        width: 14rem;
        padding: 10px;
        border: 2px solid $darkBlueColor;

        & > h3 {
          margin: 0;
          font-weight: normal;
        }
      }

      a {
        width: 100%;
      }
    }
  }

  .error-color {
    color: $icon-danger-color;
  }

  .success-color {
    color: $icon-success-color;
  }

  .stopped-color {
    color: $grey-neutral-50;
  }
}
</style>
