<template>
  <div class="h-column-center container">
    <div
      v-if="!serverInfo.isCloud && user.role === 'admin'"
      class="divider get-archive"
    >
      <div class="v-center">
        <p>
          Generate and download support TAR file (send this to rentouch for
          debug purposes).
        </p>
        <button class="next-btn valid" @click="getArchive($event)">
          Get-TAR
        </button>
      </div>
      <p
        v-if="getTarError != ''"
        class="f-center fs-semi-medium error"
        role="alert"
      >
        {{ getTarError }}
      </p>
    </div>

    <h1 class="page-title fs-extra-large">Settings</h1>

    <div class="settings-grid">
      <div v-if="showSettings" class="general-settings-container">
        <h2 class="fs-large">General</h2>
        <div class="row label-wrap all-sessions-container">
          <hover-box class="hover-box">
            If toggled on, all users are able to see all the PIs, no matter
            whether or not they are part of a participating team.
          </hover-box>
          <label for="all-sessions-switch" class="fs-semi-medium fc-black"
            >Users see all Sessions</label
          >
          <base-switch
            id="all-sessions-switch"
            class="all-sessions-switch"
            data-testid="all-sessions-switch"
            :disabled="isSaving"
            :checked="usersSeeAllSessions"
            @change="handleAllSessionsChange"
          />
        </div>
        <div class="row label-wrap auto-logout-container">
          <hover-box left class="hover-box">
            Set the time in minutes before a user is automatically logged out
            due to inactivity. This helps secure your account by reducing
            unauthorized access from unattended sessions.
          </hover-box>
          <span class="fs-semi-medium fc-black"
            >Automatic logout after inactivity of</span
          >
          <base-numeric
            :value="autoLogoutAfter"
            class="logout-after-numeric"
            data-testid="logout-after-numeric"
            min="5"
            :maxlength="5"
            :disabled="!autoLogoutEnabled"
            @input="handleAutoLogoutAfterChange"
          />
          <span class="fs-semi-medium fc-black">minutes</span>
          <base-switch
            class="automatic-logout-switch"
            data-testid="automatic-logout-switch"
            :disabled="isSaving"
            :checked="autoLogoutEnabled"
            @change="handleAutoLogoutEnabled"
          />
        </div>
      </div>

      <div class="audit-log-column">
        <h2 class="fs-large">Audit Log</h2>
        <form class="general-information" action="" autocomplete="off">
          <div class="row label-wrap">
            <label class="fs-semi-medium fc-black" for="start-date-input">
              Start date
            </label>
          </div>
          <div class="row input-block">
            <input
              id="start-date-input"
              v-model="history.start.value"
              type="datetime-local"
              :max="history.max"
              :class="{ 'date-error': history.start.error }"
            />
          </div>
          <p v-if="history.start.error" class="fc-red f-center" role="alert">
            {{ history.start.error }}
          </p>

          <div class="row label-wrap">
            <label class="fs-semi-medium fc-black" for="end-date-input">
              End date
            </label>
          </div>
          <div class="row input-block">
            <input
              id="end-date-input"
              v-model="history.end.value"
              type="datetime-local"
              :max="history.max"
              :class="{ 'date-error': history.end.error }"
            />
          </div>
          <p v-if="history.end.error" class="fc-red f-center" role="alert">
            {{ history.end.error }}
          </p>

          <div class="h-column-center export-btn-wrap">
            <base-button secondary @click.prevent="exportAuditLogs()">
              Export Logs
            </base-button>
            <p
              v-if="exportAuditLogsError"
              class="f-center fs-semi-medium error"
              role="alert"
            >
              {{ exportAuditLogsError }}
            </p>
          </div>
        </form>
      </div>
    </div>

    <div class="save-settings-container">
      <feedback-button
        :disabled="isSaveButtonDisabled || !isFormValid"
        success-message="Settings saved successfully."
        @click="saveSettings"
      >
        Save Changes
      </feedback-button>
    </div>

    <div
      v-if="canDeleteCompany()"
      class="divider get-archive"
      data-testid="delete-company"
    >
      <div class="h-column-center">
        <h2 class="fs-extra-large">Delete Company</h2>
        <div class="icon-box warn-box">
          <div class="icon"></div>
          ATTENTION: You can not undo this operation. If you delete your
          Company, all your data (sessions, users and configurations) are gone
          for ever!
          <div class="h-column-center">
            <button class="next-btn valid red" @click="showDeleteCompanyModal">
              Delete company
            </button>
          </div>
        </div>
      </div>
    </div>

    <delete-modal ref="delete" title="Delete Company" @delete="deleteCompany">
      <div class="popup-content settings-input-wrap">
        <div class="row input-block">
          <p class="explanation fs-semi-medium f-center">
            Type in your password to confirm
          </p>
          <div class="col h-column-center">
            <div class="form-group">
              <input
                id="password"
                v-model="password"
                type="password"
                class="text-input f-center no-error"
                placeholder="Password"
                @keyup.enter.prevent=""
              />
            </div>
          </div>
        </div>
      </div>
    </delete-modal>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseNumeric from "@/components/BaseNumeric.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import DeleteModal from "@/components/DeleteModalLegacy.vue";
import FeedbackButton from "@/components/FeedbackButton.vue";
import HoverBox from "@/components/HoverBox.vue";
import { isFeatureEnabled } from "@/feature";
import { AdminHandler } from "@/handlers/adminHandler";
import { CompanyHandler } from "@/handlers/companyHandler";
import { ServerHandler } from "@/handlers/serverHandler";
import { SessionsHandler } from "@/handlers/sessionsHandler";
import { SupportHandler } from "@/handlers/supportHandler";
import { TraceHandler } from "@/handlers/traceHandler";
import { UnitHandler } from "@/handlers/unitHandler";
import { UserHandler } from "@/handlers/userHandler";
import { localDateToUnixTimestamp, unixTimestampToDate } from "@/utils";
import { formatDate } from "@/utils/filters";
import { mapGetters } from "vuex";

export default {
  name: "SettingsPage",
  components: {
    DeleteModal,
    BaseButton,
    HoverBox,
    BaseSwitch,
    BaseNumeric,
    FeedbackButton,
  },
  data() {
    const startDate = new Date();
    startDate.setUTCDate(startDate.getDate() - 2);
    startDate.setUTCHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setUTCHours(23, 59, 0, 0);

    return {
      isDirty: false,
      isSaving: false,
      autoLogoutEnabled: false,
      autoLogoutAfter: 0,
      user: {
        role: "",
        username: "",
        companyAdmin: false,
        originalUsername: "",
        password: "",
      },
      history: {
        start: {
          value: startDate.toISOString().substring(0, 16),
          error: "",
        },
        end: {
          value: endDate.toISOString().substring(0, 16),
          error: "",
        },
        max: new Date().toISOString().substring(0, 16),
      },
      licenseString: "",
      hasLicenseStringError: false,
      licenseStringError: "",
      licenseInfo: {},
      serverInfo: {
        isCloud: true,
      },
      hold: false,
      password: "",
      getTarError: "",
      exportAuditLogsError: "",
      usersSeeAllSessions: false,
      loadingUsersSeeAllSessions: true,
      units: null,
    };
  },
  computed: {
    ...mapGetters(["company", "session"]),
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    traceHandler() {
      return new TraceHandler(this.company, this.session);
    },
    supportHandler() {
      return new SupportHandler(this.company, this.session);
    },
    serverHandler() {
      return new ServerHandler(this.company, this.session);
    },
    userHandler() {
      return new UserHandler(
        this.company,
        this.session,
        3,
        this.isRestApiEnabled
      );
    },
    adminHandler() {
      return new AdminHandler(
        this.company,
        this.session,
        2,
        this.isRestApiEnabled
      );
    },
    sessionsHandler() {
      return new SessionsHandler(this.company, this.session);
    },
    companyHandler() {
      return new CompanyHandler(this.company, this.session, 3, true);
    },
    unitHandler() {
      return new UnitHandler(
        this.company,
        this.session,
        3,
        this.isRestApiEnabled
      );
    },
    showSettings() {
      return this.user.role === "admin";
    },
    isSaveButtonDisabled() {
      if (this.autoLogoutEnabled && !this.autoLogoutAfter) {
        return true;
      }
      return this.isSaving || !this.isDirty;
    },
    isFormValid() {
      if (this.autoLogoutEnabled) {
        return this.autoLogoutAfter >= 5;
      }
      return true;
    },
  },
  async mounted() {
    this.getSettings();
    await this.getUserProfile();

    if (this.user.role === "admin") {
      await this.getLicense();
      this.getLicenseDetails();
      await this.fetchServerInfo();

      this.$set(
        this.serverInfo,
        "buildDateFormatted",
        formatDate(unixTimestampToDate(this.serverInfo.buildDate))
      );
      this.getUsersSeeAllSessions();
      this.getUnits();
    }
  },
  methods: {
    handleAutoLogoutEnabled(value) {
      this.isDirty = true;
      this.autoLogoutEnabled = value;

      if (!value) {
        this.autoLogoutAfter = 0;
      }
    },
    handleAutoLogoutAfterChange(value) {
      this.isDirty = true;
      this.autoLogoutAfter = value;
    },

    handleAllSessionsChange(value) {
      this.isDirty = true;
      this.usersSeeAllSessions = value;
    },

    async saveSettings() {
      this.isSaving = true;

      try {
        await Promise.all([
          this.setUsersSeeAllSessions(this.usersSeeAllSessions),
          this.setAutoLogoutAfter(this.autoLogoutAfter),
        ]);
        this.isDirty = false;
      } finally {
        this.isSaving = false;
      }
    },

    async setUsersSeeAllSessions(value) {
      await this.sessionsHandler.setUsersSeeAllSessions(value);
    },

    async setAutoLogoutAfter(value) {
      const settings = { autoLogoutAfter: value };
      await this.companyHandler.setCompanySettings(settings);
    },

    async getSettings() {
      const { data } = await this.companyHandler.getCompanySettings();

      this.autoLogoutAfter = data.autoLogoutAfter;
      this.autoLogoutEnabled = data.autoLogoutAfter > 0;
    },

    async fetchServerInfo() {
      const isRestApiEnabled = isFeatureEnabled(this.$route, "rest-api");
      this.serverInfo = await this.serverHandler.getServerInfo(
        isRestApiEnabled
      );
    },
    async getUserProfile() {
      const { data: user } = await this.userHandler.getProfile();
      this.user = user;
      this.user.username = this.user.username || this.username;
      this.user.originalUsername = this.username;
      this.user.companyAdmin =
        this.user.role === "admin" && this.user.units.length === 0;
    },
    async getLicense() {
      this.licenseString = await this.adminHandler.getLicense();
    },
    async getUnits() {
      const { data: units } = await this.unitHandler.getUnitsByUser();
      this.units = units;
    },
    async getUsersSeeAllSessions() {
      this.usersSeeAllSessions =
        await this.sessionsHandler.getUsersSeeAllSessions();
      this.loadingUsersSeeAllSessions = false;
    },

    getLicenseDetails() {
      this.userHandler.getLicenseDetails().then((res) => {
        this.licenseInfo = res;
        this.$set(
          this.licenseInfo,
          "valid_until",
          formatDate(unixTimestampToDate(this.licenseInfo.expiryDate))
        );
      });
    },
    showDeleteCompanyModal() {
      this.$refs.delete.show();
    },
    getArchive($event) {
      let downloadButton = $($event.target);
      if (!downloadButton.hasClass("hold")) {
        downloadButton.addClass("hold");
        this.getTarError = "";
        let base64Data = "";
        this.supportHandler.getTar().then(
          (res) => {
            downloadButton.removeClass("hold");
            if (!res["success"]) {
              this.getTarError = res["error"];
              return;
            }
            this.saveData(base64Data, "support.tar");
            downloadButton.removeClass("hold");
          },
          (err) => {
            this.getTarError = err;
            downloadButton.removeClass("hold");
          },
          (progress) => {
            base64Data += progress["data"];
          }
        );
      }
    },
    async exportAuditLogs() {
      this.validateDateValue("start");
      this.validateDateValue("end");
      this.validateDateInterval();
      if (!this.history.start.error && !this.history.end.error) {
        this.hold = true;
        this.exportAuditLogsError = "";
        let base64Data = "";
        const start = new Date(this.history.start.value);
        const end = new Date(this.history.end.value);
        const startFormatted = formatDate(start).replace(/[^0-9]/g, "");
        const endFormatted = formatDate(end).replace(/[^0-9]/g, "");
        const fileName = `log-${startFormatted}-${endFormatted}.xlsx`;
        return this.traceHandler
          .getAuditLogs(
            localDateToUnixTimestamp(start),
            localDateToUnixTimestamp(end)
          )
          .then(
            (res) => {
              if (res.success) {
                this.saveData(base64Data, fileName);
              } else {
                this.exportAuditLogsError = res.error;
              }
              this.hold = false;
            },
            (err) => {
              this.exportAuditLogsError = err;
              this.hold = false;
            },
            (progress) => {
              base64Data += progress.data;
            }
          );
      }
    },
    saveData(data, fileName) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let blob = this.base64ToBlob(data, "octet/stream");
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    base64ToBlob(base64, mime) {
      mime = mime || "";
      var sliceSize = 1024;
      var byteChars = window.atob(base64);
      var byteArrays = [];

      for (
        var offset = 0, len = byteChars.length;
        offset < len;
        offset += sliceSize
      ) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mime });
    },
    validateDateValue(key) {
      this.history[key].error =
        this.history[key].value === "" ? `Please enter the ${key} date` : "";
    },
    validateDateInterval() {
      if (this.history.start.value && this.history.end.value) {
        const isValidInterval =
          new Date(this.history.start.value) <=
          new Date(this.history.end.value);
        this.history.start.error = isValidInterval
          ? ""
          : "The start date has to be sooner in time than the end date";
        this.history.end.error = isValidInterval
          ? ""
          : "The end date has to be later in time than the start date";
      }
    },
    validateLicenseString() {
      if (this.licenseString.length === 0) {
        this.hasLicenseStringError = true;
        this.licenseStringError = "License string is required.";
      } else {
        this.hasLicenseStringError = false;
        this.licenseStringError = "";
      }
    },
    updateLicense() {
      this.validateLicenseString();
      if (!this.hasLicenseStringError) {
        this.adminHandler.setLicense(this.licenseString).then((res) => {
          if (res) {
            console.log("License updated successfully");
            this.getLicenseDetails();
          } else {
            this.hasLicenseStringError = true;
            this.licenseStringError = "Invalid license string";
          }
        });
      }
    },
    deleteCompany() {
      this.adminHandler.deleteCompany(this.password).then(
        (res) => {
          if (res.success) {
            document.location = "https://piplanning.io";
          } else {
            this.$refs.delete.fail(errorMessage(res.errorCode));
          }
        },
        () => {
          this.$refs.delete.fail(errorMessage(null));
        }
      );

      function errorMessage(code) {
        switch (code) {
          case 1:
            return "You are not allowed to delete the company.";
          case 2:
            return "Wrong password.";
          default:
            return "Could not delete the company, please try again.";
        }
      }
    },
    canDeleteCompany() {
      // Keeping this method because delete company will be needed eventually
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.container {
  width: 100%;
  max-width: 1300px;
  margin: 18px auto 64px auto;
  padding: 4rem 4rem 0;
  margin-top: 50px;
}

.general-information {
  width: 100%;
  max-width: none;
  margin: 0;

  .input-block .form-group {
    position: relative;
    width: 100%;
  }

  .input-block .form-group input {
    padding-right: 54px;
  }
}

.general-information input.date-error {
  border-color: $redColor;
  color: $redColor;
}

.no-error {
  color: $blackColor;
}

.input-block .form-group input.readonly {
  color: $greyColor;
  width: 100%;
}

.license-activation-main {
  width: 100%;
  margin-top: 50px;
}

.divider:before {
  left: 0;
  right: 0;
}

.hover-box {
  flex-shrink: 0;
}

.license-activation-main p {
  font-size: 1.25rem;
}

.license-activation {
  display: flex;
}

.license-activation-left {
  width: 60%;
}

.license-activation-right {
  width: 40%;
  margin-top: 38px;
}

.license-textarea {
  width: 92%;
  height: 250px;
  margin: 20px 0;
  font-size: 1.5rem;
  border-bottom: 3px solid $greyColor;
}

.renew-license-info {
  line-height: 1.5;
}

.license-released-info {
  margin-top: 40px;
}

.license-released-info p {
  margin: 5px 0;
}

.license-activation-right p {
  margin: 5px 0;
}

.license-activation-right p.current-license-information {
  margin-bottom: 20px;
}

.license-activation-right p span,
.license-released-info p span {
  display: inline-block;
  width: 200px;
}

.license-activation .next-btn {
  margin: 30px 0 25px;
}

.license-activation textarea.error {
  border-color: $greyColor !important;
  border-bottom: 3px solid $redColor !important;
}

.license-activation textarea.no-error {
  border-color: $greyColor !important;
  border-bottom: 3px solid $darkBlueColor !important;
}

.settings-input-wrap .input-block {
  margin-top: 50px;
}

.settings-input-wrap .input-block .form-group input {
  padding-left: 0;
}

.get-archive {
  width: 100%;
  padding: 50px 0 0 0;
  margin-top: 50px;

  .next-btn {
    margin: 0 20px;
    width: 100%;
    outline: revert;
  }

  .next-btn.hold {
    padding-left: 60px;
  }
}

.explanation {
  margin: 20px 0;
}

.warn-box button {
  margin-top: 20px !important;
}

input[type="datetime-local"] {
  width: 100%;
  font-size: $font-medium;
}

.all-sessions-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.all-sessions-switch,
.automatic-logout-switch {
  margin-left: auto;
}

.settings-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
}

.audit-log-column {
  max-width: 300px;
  width: 300px;
  flex: 0 0 auto;
}

.general-settings-container {
  flex: 1 1 auto;
  min-width: 300px;
}

.page-title {
  text-align: center;
  margin: 2rem 0;
  width: 100%;
}

.general-settings-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .row {
    width: 100%;
    max-width: 640px;
  }
}

.logout-after-numeric {
  width: 100px;
  padding-left: 20px;
}

.auto-logout-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.save-settings-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
</style>
