import { RemoteCaller } from "./remoteCaller";

export class SupportHandler {
  constructor(company, getWsSession, apiVersion = 6) {
    this.remote = new RemoteCaller(
      "piplanning",
      apiVersion,
      null,
      getWsSession,
      "support"
    );
  }

  getTar() {
    return this.remote.progressiveCall("get_tar");
  }
}
