<template>
  <base-modal
    :is-open="showModal"
    class="simulation-modal"
    :title="modalTitle"
    description="Simulation setup wizard."
    @close="showModal = false"
  >
    <div v-if="!isCreated && !isInProgress">
      <base-select
        v-if="!error"
        v-model="templateId"
        :options="templateOptions"
      />

      <button
        v-if="!error"
        class="next-btn valid"
        @click="simulation(templateSession)"
      >
        Create
      </button>

      <p v-if="error" class="server-error">{{ error }}</p>
      <button v-if="error" class="next-btn valid" @click="ok()">Close</button>
    </div>

    <div
      v-else-if="isCreated && newTeams.length > 0 && !isInProgress"
      class="popup-content"
    >
      <table class="center">
        <thead>
          <tr>
            <th scope="col">Team</th>
            <th scope="col">Password</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(team, index) in newTeams" :key="index">
            <td>{{ team.name }}</td>
            <td>{{ team.pw }}</td>
          </tr>
        </tbody>
      </table>

      <button class="next-btn valid" @click="ok()">Close</button>
    </div>

    <div v-else-if="isInProgress" class="popup-content">
      <div class="loading-wrapper">
        <div class="spinner" :class="{ hold: isInProgress }"></div>
        <p>initializing simulation...</p>
      </div>
    </div>
  </base-modal>
</template>

<script>
import serverCaller from "@/components/serverCaller";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseModal from "../BaseModal.vue";
import { SessionsHandler } from "@/handlers/sessionsHandler";
import { mapGetters } from "vuex";

export default {
  name: "SimulationModal",
  components: { BaseSelect, BaseModal },
  mixins: [serverCaller],
  data() {
    return {
      expandSelect: false,
      selected: false,
      availableTemplateSessions: [],
      templateId: -1,
      newTeams: [],
      title: "Please select the simulation you want to create",
      isInProgress: false,
      isCreated: false,
      error: "",
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(["company", "session"]),
    sessionsHandler() {
      return new SessionsHandler(this.company, this.session);
    },
    modalTitle() {
      return this.isInProgress ? "" : this.title;
    },
    templateOptions() {
      return this.availableTemplateSessions.map((template) => ({
        label: template.name,
        value: template.id,
      }));
    },
    templateSession() {
      return this.availableTemplateSessions.find(
        ({ id }) => id === this.templateId
      );
    },
  },
  mounted() {
    this.getAvailableTemplateSessions();
  },
  methods: {
    show() {
      this.initialize();
      this.showModal = true;
    },
    ok() {
      this.showModal = false;
    },
    fail(message) {
      this.error = message;
    },
    async simulation(type) {
      if (this.isInProgress) {
        return;
      }
      this.isInProgress = true;

      try {
        const result = await this.sessionsHandler.createTemplateSession(
          type.id
        );
        if (result.success) {
          this.displayNewTeams(result.new_teams);
        } else {
          this.fail(result.error);
        }
      } catch (_error) {
        this.fail("Could not create the simulation. Please try again.");
      } finally {
        this.isInProgress = false;
      }
    },
    displayNewTeams(newTeams) {
      this.isCreated = true;
      this.newTeams = newTeams;
      this.ok();
    },
    getAvailableTemplateSessions() {
      this.sessionsHandler.getTemplateSessions().then((sessions) => {
        this.availableTemplateSessions = sessions
          .map((session) => (session.name ? session : processOldStyle(session)))
          .filter((session) => session.selectable);
        this.templateId = this.availableTemplateSessions[0].id;
      });

      //TODO remove as soon as backend is adjusted
      function processOldStyle(session) {
        return {
          id: session,
          name: session.charAt(0).toUpperCase() + session.slice(1),
          selectable: !["empty", "playaround"].includes(session),
        };
      }
    },

    initialize() {
      this.isInProgress = false;
      this.isCreated = false;
      this.newTeams = [];
      this.error = "";
      this.title = "Please select the simulation you want to create";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.simulation-modal table {
  border-collapse: collapse;
  width: 80%;
  font-size: 1.25rem;
  margin-top: 40px;
}

.simulation-modal th,
.simulation-modal td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.simulation-modal table.center {
  margin-left: auto;
  margin-right: auto;
}

.simulation-modal .loading-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 150px;
}

.spinner {
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  cursor: pointer;
  pointer-events: none;
  content: "";
  background: url(~@/assets/spinner.gif) no-repeat center;
  height: 60px;
  width: 60px;
}
</style>
