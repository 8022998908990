import { RemoteCaller } from "./remoteCaller";
import { authAPIUrl } from "@/environment";
import { IdentityProviderService } from "@/services/identityProvider.service";

function renameConnectionSettings(settings) {
  return {
    id: settings.idp_id,
    type: settings.type,
    name: settings.name,
    issuer: settings.config.issuer,
    authorizationEndpoint: settings.config.authorization_endpoint,
    tokenEndpoint: settings.config.token_endpoint,
    userinfoEndpoint: settings.config.userinfo_endpoint,
    clientId: settings.config.client_id,
    authMethod: settings.config.clientAuthenticationMethod,
    emailClaim: settings.config.emailClaim,
    groupsClaim: settings.config.groupsClaim,
    scope: settings.config.scope,
  };
}

function mapSettings(mappings, config) {
  return mappings.reduce((remappedConfig, [from, to]) => {
    if (config[from]) {
      remappedConfig[to] = config[from];
    }
    return remappedConfig;
  }, {});
}

export class IdentityProviderHandler {
  constructor(company, getWsSession, apiVersion, isUsingRest = false) {
    this.remote = new RemoteCaller(
      "auth",
      apiVersion || 3,
      company,
      getWsSession,
      "idps"
    );

    this.isUsingRest = isUsingRest;
    this.identityProviderService = new IdentityProviderService(
      authAPIUrl,
      company
    );
  }

  createAutodiscoverableOpenIdConnectProvider(
    name,
    issuer,
    clientId,
    clientSecret,
    clientAuthenticationMethod,
    emailClaim,
    groupsClaim,
    scope
  ) {
    if (this.isUsingRest) {
      return this.identityProviderService.addOIDC(name, {
        issuer,
        client_id: clientId,
        client_secret: clientSecret,
        clientAuthenticationMethod,
        emailClaim,
        groupsClaim,
        scope,
      });
    }
    return this.remote.call("add_oidc", [
      {
        issuer,
        client_id: clientId,
        client_secret: clientSecret,
        clientAuthenticationMethod,
        emailClaim,
        groupsClaim,
        scope,
      },
      name,
    ]);
  }

  createManualOpenIdConnectProvider(
    name,
    authorizationEndpoint,
    tokenEndpoint,
    userinfoEndpoint,
    clientId,
    clientSecret,
    clientAuthenticationMethod,
    emailClaim,
    groupsClaim,
    scope
  ) {
    if (this.isUsingRest) {
      return this.identityProviderService.add_oidc(name, {
        authorization_endpoint: authorizationEndpoint,
        token_endpoint: tokenEndpoint,
        userinfo_endpoint: userinfoEndpoint,
        client_id: clientId,
        client_secret: clientSecret,
        clientAuthenticationMethod,
        emailClaim,
        groupsClaim,
        scope,
      });
    }

    return this.remote.call("add_oidc", [
      {
        authorization_endpoint: authorizationEndpoint,
        token_endpoint: tokenEndpoint,
        userinfo_endpoint: userinfoEndpoint,
        client_id: clientId,
        client_secret: clientSecret,
        clientAuthenticationMethod,
        emailClaim,
        groupsClaim,
        scope,
      },
      name,
    ]);
  }

  createSAML() {
    if (this.isUsingRest) {
      return this.identityProviderService.addSAML();
    }
    return this.remote.call("add_saml", []);
  }

  async getConfigurations() {
    var response;
    if (this.isUsingRest) {
      response = await this.identityProviderService.getProviders();
    } else {
      response = await this.remote.call("get_idps");
    }
    if (!response.success) {
      return response;
    }

    const r = {
      success: true,
      data: response.data.map(renameConnectionSettings),
    };
    return r;
  }

  updateConfiguration(id, config) {
    const params = {};
    if (config.name) {
      params.name = config.name;
    }

    const connectionConfig = mapSettings(
      [
        ["clientId", "client_id"],
        ["clientSecret", "client_secret"],
        ["issuer", "issuer"],
        ["authorizationEndpoint", "authorization_endpoint"],
        ["tokenEndpoint", "token_endpoint"],
        ["userinfoEndpoint", "userinfo_endpoint"],
        ["authMethod", "clientAuthenticationMethod"],
        ["emailClaim", "emailClaim"],
        ["groupsClaim", "groupsClaim"],
        ["scope", "scope"],
      ],
      config
    );

    if (Object.entries(connectionConfig).length > 0) {
      params.config = connectionConfig;
    }

    if (this.isUsingRest) {
      return this.identityProviderService.updateOIDC(id, params);
    }

    return this.remote.call("update_oidc", [id], params);
  }

  deleteConfiguration(id) {
    if (this.isUsingRest) {
      return this.identityProviderService.deleteProvider(id);
    }
    return this.remote.call("delete_idp", [id]);
  }
}
