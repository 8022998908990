import { RemoteCaller } from "./remoteCaller";
import { authAPIUrl } from "@/environment";
import { CompanyService } from "@/services/company.service";

export class CompanyHandler {
  constructor(company, getWsSession, apiVersion = 3, isUsingRest = false) {
    this.remote = new RemoteCaller(
      "auth",
      apiVersion,
      company,
      getWsSession,
      "companies"
    );

    this.companyService = new CompanyService(authAPIUrl, company);

    this.isUsingRest = isUsingRest;
  }

  getPasswordRules() {
    if (this.isUsingRest) {
      return this.companyService.getPasswordRules();
    }
    return this.remote.call("get_password_rules");
  }

  setTheme(themeName) {
    if (this.isUsingRest) {
      return this.companyService.setTheme(themeName);
    }
    return this.remote.call("set_theme", [themeName]);
  }

  getTheme() {
    if (this.isUsingRest) {
      return this.companyService.getTheme();
    }
    return this.remote.call("get_active_theme", []);
  }

  async setSSOLoginTheme() {
    if (this.isUsingRest) {
      return this.companyService.setTheme("pip-theme-sso-only");
    }
    return this.setTheme("pip-theme-sso-only");
  }

  async setStandardLoginTheme() {
    if (this.isUsingRest) {
      return this.companyService.setTheme("pip-theme");
    }
    return this.setTheme("pip-theme");
  }

  async ssoOnlyLogin() {
    var theme;
    if (this.isUsingRest) {
      theme = await this.companyService.getTheme();
    } else {
      theme = await this.getTheme();
    }

    return theme.data === "pip-theme-sso-only";
  }

  async getCompanySettings() {
    if (this.isUsingRest) {
      return this.companyService.getSettings();
    }
    return this.remote.call("get_company_settings");
  }

  async setCompanySettings(settings) {
    const payload = {
      auto_logout_after: settings.autoLogoutAfter,
    };

    if (this.isUsingRest) {
      return this.companyService.setSettings(payload);
    }
    return this.remote.call("set_company_settings", [payload]);
  }
}
