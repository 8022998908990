<template>
  <base-modal
    :is-open="showModal"
    class="duplicate-modal"
    title="Duplicate session"
    description="Choose how you want to create a copy of this session."
    @close="showModal = false"
  >
    <div class="layout">
      <div class="column">
        <h2 class="secondary-heading">Prepare new PI</h2>
        <div class="text">
          Keep the configuration of the PI but update the teams, iteration names
          and iteration mapping.
        </div>
        <base-button @click="duplicate(false)">Prepare new PI</base-button>
      </div>
      <div class="column">
        <h2 class="secondary-heading">Duplicate this PI</h2>
        <div class="text">
          Duplicate this PI but update the teams. Mostly used when the team
          composition changes before the PI Planning starts.
        </div>
        <base-button secondary @click="duplicate(true)">Duplicate</base-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "../BaseModal.vue";
import { mapActions, mapGetters } from "vuex";
import { SessionDuplicationService } from "@/handlers/sessionDuplicationService";
import { AlmConfigurationHandler } from "@/handlers/almConfigurationHandler";
import BaseButton from "@/components/BaseButton.vue";
import { captureExceptionWithContext } from "@/sentry";
import { trackEvent } from "@/analytics/track";
import { isFeatureEnabled } from "@/feature";
import {
  safepiplanningsDuplicateModalOptionSelected,
  safepiplanningsDuplicateModalSeen,
} from "@/analytics/events";

export default {
  name: "DuplicateModal",
  components: { BaseButton, BaseModal },
  data() {
    return {
      showModal: false,
      sessionId: "",
    };
  },
  computed: {
    ...mapGetters(["company", "session"]),
    almConfigurationHandler() {
      return new AlmConfigurationHandler(this.company, this.session);
    },
  },
  methods: {
    ...mapActions("toast", ["showMessage"]),
    show(sessionId) {
      trackEvent(safepiplanningsDuplicateModalSeen());

      this.showModal = true;
      this.sessionId = sessionId;
    },
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    async duplicate(withIterations) {
      trackEvent(safepiplanningsDuplicateModalOptionSelected(withIterations));

      try {
        const sessionInfo =
          await this.almConfigurationHandler.getAlmConfiguration(
            this.sessionId
          );
        const almHandler = this.$almHandler(sessionInfo);
        await new SessionDuplicationService(
          this.sessionId,
          sessionInfo,
          almHandler,
          this.$store,
          this.isRestApiEnabled
        ).duplicateSession(withIterations);
        this.$store.commit("clearStateManager");
        this.$router.push({ name: "addnewsafepiplanning" });
      } catch (error) {
        captureExceptionWithContext(error);
        this.showMessage({
          message: `Could not duplicate session: ${error.message}`,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.duplicate-modal {
  & > :deep(div) {
    width: 700px;
  }

  .secondary-heading {
    margin: 1.33rem 0;
    font-size: 1rem;
    font-weight: bold;
  }

  .layout {
    display: flex;
    justify-content: space-around;

    .column {
      width: 40%;

      button {
        width: 100%;
      }
    }

    .text {
      height: 100px;
    }
  }
}
</style>
