var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-section h-column-center"},[_c('h2',[_vm._v("ALM Connections")]),_c('div',{staticClass:"alm-tools-wrap"},[_c('data-table',{scopedSlots:_vm._u([{key:"default",fn:function({ align }){return [_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("ALM Tool")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),(_vm.hasUnit)?_c('th',{attrs:{"scope":"col"}},[_vm._v("Unit")]):_vm._e(),_c('th',{class:align.center,attrs:{"scope":"col"}},[_vm._v("Status")]),_c('td',{staticClass:"th",class:[align.center, _vm.$style['actions-column']]},[_vm._v(" Edit ")]),_c('td',{staticClass:"th",class:[align.center, _vm.$style['actions-column']]},[_vm._v(" Action ")])]),_vm._l((_vm.connections),function(connection){return _c('tr',{key:connection.id},[_c('td',[_vm._v(_vm._s(connection.alm_name))]),_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(connection.name))]),(_vm.hasUnit)?_c('td',[_vm._v(_vm._s(connection.unitName))]):_vm._e(),_c('td',[_c('div',{class:_vm.$style['status-column']},[(connection.state === 'error')?_c('base-icon',{class:_vm.$style['warning-color'],attrs:{"icon":"warning","role":"img","title":"Connection to the ALM tool failed. To get more information and abillity to fix it go to Action / Events."}}):(connection.state === 'okay')?_c('base-icon',{class:_vm.$style['success-color'],attrs:{"icon":"success","role":"img","title":"Connection is working"}}):_vm._e()],1)]),_c('td',{class:[align.center, _vm.$style['actions-column']]},[_c('router-link',{attrs:{"to":{
                name: 'editAlmConnection',
                params: {
                  connectionId: connection.id,
                  almType: connection.alm_type,
                },
              },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('action-button',{attrs:{"action":"edit","title":"Edit"},on:{"click":navigate}})]}}],null,true)})],1),_c('td',{class:[align.center, _vm.$style['actions-column']]},[_c('action-menu',{attrs:{"title":"Action menu","data-testid":`action-menu-${connection.name}`}},[_c('li',{staticClass:"list-item",attrs:{"tabindex":"-1","role":"menuitem"},on:{"click":function($event){return _vm.edit(connection)}}},[_vm._v(" Edit ")]),_c('li',{staticClass:"list-item",attrs:{"tabindex":"-1","role":"menuitem"},on:{"click":function($event){return _vm.removeModal(connection)}}},[_vm._v(" Delete ")]),_c('li',{staticClass:"list-item",attrs:{"tabindex":"-1","role":"menuitem"},on:{"click":function($event){return _vm.event(connection)}}},[_vm._v(" Events ")]),_vm._l((_vm.extraAlmActions(connection.alm_tool_type)),function(action){return [_c('li',{key:action.name,staticClass:"list-item",attrs:{"tabindex":"-1","role":"menuitem"},on:{"click":function($event){return action.event(connection)}}},[_vm._v(" "+_vm._s(action.name)+" ")])]})],2)],1)])})]}}])})],1),_c('base-button',{on:{"click":_vm.goNext}},[_vm._v("Add new ALM Connection")]),_c('delete-modal',{ref:"delete",attrs:{"title":"Delete ALM Connection"},on:{"delete":_vm.deleteConnection}},[_vm._v(" "+_vm._s(_vm.toDelete.alm_name)+" '"+_vm._s(_vm.toDelete.name)+"' ")]),_c('reload-workspace-modal',{attrs:{"is-open":Boolean(_vm.reloadingWorkspace.connectionIdToSync),"done":_vm.reloadingWorkspace.isDone,"error-message":_vm.reloadingWorkspace.errorMessage},on:{"close":function($event){_vm.reloadingWorkspace.connectionIdToSync = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }