import Vue from "vue";
import Vuex from "vuex";

import { toastStoreConf } from "./toast.js";

Vue.use(Vuex);

const noEntities = { team: [], art: [], train: [] };
const noPermissions = {
  read: noEntities,
  edit: noEntities,
  session: noEntities,
};

export function getStoreConf() {
  return {
    modules: {
      toast: toastStoreConf,
    },
    state: {
      crossNotAvailable: false,
      expireDays: null,
      onPrem: false,
      wsconnection: {},
      user: {},
      authServerErr: "",
      startedPageState: "default",
      stateManager: [],
      isSSOEnabled: false,
      order: {},
      hasUnits: false,
      license: {},
    },
    getters: {
      company(state) {
        return state.user.company;
      },
      user(state) {
        return state.user;
      },
      isAdmin(state) {
        return state.user.role === "admin";
      },
      isPlanningIntervalAdmin(state) {
        return state.user.role === "planning_interval_admin";
      },
      isObserver(state) {
        return state.user.role === "observer";
      },
      isTraining(state, getters) {
        return getters.company && getters.company.startsWith("pips-");
      },
      session(state) {
        return () => {
          if (!state.wsconnection.session?.isOpen) {
            throw new Error("Autobahn session is not open");
          }
          return state.wsconnection.session;
        };
      },
      isRetryingConnection(state) {
        return state.wsconnection?.isRetrying;
      },
      canEditTeam(state) {
        return hasAccess(state.user, "team", "edit");
      },
      canEditArt(state) {
        return hasAccess(state.user, "art", "edit");
      },
      canEditTrain(state) {
        return hasAccess(state.user, "train", "edit");
      },
      canEditSessionWithArt(state) {
        return hasAccess(state.user, "art", "session");
      },
      canEditSessionWithTrain(state) {
        return hasAccess(state.user, "train", "session");
      },
    },
    mutations: {
      license: (state, license) => {
        state.license = license;
      },
      notAvailable(state) {
        state.crossNotAvailable = true;
      },
      available(state) {
        state.crossNotAvailable = false;
      },
      expireDays(state, days) {
        state.expireDays = days;
      },
      onPrem(state, onPrem) {
        state.onPrem = onPrem;
      },
      enableSSO(state) {
        state.isSSOEnabled = true;
      },
      disableSSO(state) {
        state.isSSOEnabled = false;
      },
      setUserInfo(state, info) {
        const [company, id] = info.authid.split(".");
        state.user = {
          company,
          id,
          role: info.authrole,
          permissions: noPermissions,
        };
      },
      setUser(state, user) {
        state.user = user;
      },
      setConnection(state, connection) {
        state.wsconnection = connection;
      },
      closeConnection(state) {
        state.wsconnection.close("wamp.close.logout");
        state.expireDays = null;
      },
      setServerError(state, err) {
        state.authServerErr = err;
      },
      setRegisterBtnState(state, scope) {
        scope.context.hold = scope.isLocked;
        scope.context.setSubmitText(scope.message);
      },
      /***** current state  *****/
      setStartedPageState(state, newState) {
        store.commit("setCurrentState", state.startedPageState);
        store.commit("setState", newState);
      },
      setState(state, newState) {
        state.startedPageState = newState;
        scrollTop();
        localStorage.setItem("currentState", JSON.stringify(newState));
      },
      setCurrentState(state, lastState) {
        store.commit("setStateManager", lastState);
        localStorage.setItem("stateStack", JSON.stringify(state.stateManager));
      },
      setStateManager(state, newState) {
        if (typeof newState === "object") {
          state.stateManager = newState;
        } else {
          state.stateManager.push(newState);
        }
      },
      getPreviousState(state) {
        store.commit("setState", state.stateManager.pop());
        localStorage.setItem("stateStack", JSON.stringify(state.stateManager));
      },
      clearStateManager(state) {
        state.stateManager = [];
        state.startedPageState = "default";
        localStorage.removeItem("stateStack");
        localStorage.removeItem("currentState");
      },
      order(
        state,
        { company, price, numberOfLicenses, validityStartDate, validityEndDate }
      ) {
        state.order = {
          company,
          price,
          numberOfLicenses,
          validityStartDate,
          validityEndDate,
        };
      },
      hasUnits(state, hasUnits) {
        state.hasUnits = hasUnits;
      },
    },
  };
}

const store = new Vuex.Store(getStoreConf());

function scrollTop() {
  $("html, body").animate({ scrollTop: 0 }, 200);
}

function hasAccess(user, entity, permission) {
  return (objOrId) => {
    const perms = user.permissions[permission][entity];
    return perms[0] < 0 || perms.includes(objOrId?.id || objOrId);
  };
}

export default store;
