import { RemoteCaller } from "./remoteCaller";

export class TraceHandler {
  constructor(company, getWsSession, apiVersion = 1) {
    this.remote = new RemoteCaller("trace", apiVersion, company, getWsSession);
  }

  getAuditLogs(start, end) {
    return this.remote.progressiveCall("get_auditlogs", [start, end]);
  }
}
