export default class SessionService {
  constructor(sessionsHandler, unitHandler) {
    this.sessionsHandler = sessionsHandler;
    this.unitHandler = unitHandler;
  }

  async getSessionUnitName(sessionId) {
    const session = await this.sessionsHandler.getSession(sessionId);
    if (session.unit) {
      const { data: units } = await this.unitHandler.getUnitsByUser();
      const unit = units.find((u) => u.id === session.unit);
      return unit.name;
    }
  }
}
