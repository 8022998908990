import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initializeGlobalVue } from "./init.js";
import {
  captureExceptionWithContext,
  captureMessageWithContext,
  initSentry,
} from "./sentry";

const EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus;
    },
  },
});

initializeGlobalVue(Vue);
initSentry(Vue).then(() => {
  window.onunhandledrejection = (event) => {
    event.preventDefault();
    const error = event.reason;
    if (error.isAxiosError) {
      const config = error.config;
      const context = {
        Request: {
          url: config.baseURL + config.url,
          correlationId: config.headers["correlation-id"],
          timeout: config.timeout,
        },
      };
      captureMessageWithContext(`Axios Error: ${error.message}`, context);
    } else {
      captureExceptionWithContext(error);
    }
  };
});

export const vm = new Vue({
  el: "#app",
  data: {},
  store,
  router,
  render: (h) => h(App),
});

// real Intercom will be inited after login if enabled
// dummy init here, so we can just call Intercom without always checking if it's available or not
window.Intercom = () => {};
