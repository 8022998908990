<template>
  <base-modal
    :title="create ? 'Create Unit' : 'Edit Unit'"
    description=""
    data-testid="edit-unit-modal"
    :is-open="showEditUnitModal"
    @close="showEditUnitModal = false"
  >
    <form
      class="general-information create-team-form h-column-center"
      autocomplete="off"
      @submit.prevent="saveTree"
    >
      <div class="row input-block">
        <div class="col">
          <div class="form-group">
            <input
              v-model="unit.name"
              type="text"
              placeholder="Name"
              aria-label="Name"
              class="text-input"
              :class="{ error: nameError }"
              @keyup="validateName()"
            />
          </div>
          <p v-if="nameError" class="error">{{ nameError }}</p>
        </div>
      </div>
    </form>
    <template name="buttons">
      <button
        class="next-btn valid fs-medium"
        :class="{ hold }"
        @click.prevent="saveTree"
      >
        {{ create ? "Create" : "Save" }}
      </button>
    </template>
  </base-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { subArray } from "./treeFunctions";
import BaseModal from "@/components/BaseModal";
import { UnitHandler } from "@/handlers/handlerFactory";
import { isFeatureEnabled } from "@/feature";

export default {
  name: "EditUnitModal",
  components: { BaseModal },
  props: {
    root: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      unit: { name: "" },
      create: false,
      nameError: "",
      hold: false,
      showEditUnitModal: false,
    };
  },
  computed: {
    ...mapGetters(["company", "session"]),
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    unitHandler() {
      return new UnitHandler(
        this.company,
        this.session,
        3,
        this.isRestApiEnabled
      );
    },
  },
  methods: {
    show(unit) {
      this.unit = unit ? unit : { name: "" };
      this.create = !unit;
      this.nameError = "";
      this.hold = false;
      this.showEditUnitModal = true;
    },
    validateName() {
      this.nameError = "";
      if (!this.unit.name) {
        this.nameError = "Name is required";
      }
    },
    async saveTree() {
      this.validateName();
      if (this.nameError || this.hold) {
        return;
      }
      if (this.create) {
        subArray(this.root, "children").push(this.unit);
      }
      this.hold = true;
      try {
        await this.unitHandler.updateUnitTree(this.root);
      } catch (err) {
        this.hold = false;
        throw new Error("Could not save the change. Please try again.");
      }
      this.showEditUnitModal = false;
      this.$emit("changed");
    },
  },
};
</script>

<style lang="scss"></style>
