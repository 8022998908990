import { createApiClient } from "@/services/apiClient";

const baseURL = "v1/units";

export class UnitService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async getUnitsByUser() {
    const rsp = await this.apiClient.get(baseURL);
    return {
      data: rsp.data.data,
      success: rsp.data.status === "success",
    };
  }

  async addUserToUnit(unitId, userId) {
    const rsp = await this.apiClient.post(`${baseURL}/${unitId}/add_user`, {
      user_id: userId,
    });

    return {
      data: rsp.data.data,
      success: rsp.data.status === "success",
    };
  }

  async getUnitTree() {
    const rsp = await this.apiClient.get(`${baseURL}/tree`);
    return {
      data: rsp.data.data,
      success: rsp.data.status === "success",
    };
  }

  async updateTree(params) {
    const rsp = await this.apiClient.patch(`${baseURL}/tree`, params, {
      timeout: 20000,
    });
    return {
      data: rsp.data.data,
      success: rsp.data.status === "success",
    };
  }

  async deleteUnit(id) {
    const rsp = await this.apiClient.delete(`${baseURL}/${id}`);
    return {
      success: rsp.data.status === "success",
    };
  }
}
